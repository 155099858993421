import React from 'react';
import { connect } from 'react-redux';
import { Menu, Dropdown } from 'antd';
import { logout } from 'ducks/app';
import { push } from 'ducks/router';
import Avatar from 'components/UI/Avatar';
import CheckRoleElement from 'components/UI/CheckRoleElement';

const mapDispatchToProps = (dispatch) => ({
  logout: (event) => {
    event.preventDefault();
    dispatch(logout());
  },
  gotoProfile: (userState) => (event) => {
    event.preventDefault();
    if (userState && userState.id) {
      dispatch(push(`/profile/view/${userState.id}`));
    }
  },
});

const mapStateToProps = (state, props) => ({
  userState: state.app.userState,
});

@connect(mapStateToProps, mapDispatchToProps)
class ProfileMenu extends React.Component {
  state = {
    count: 7,
  };

  addCount = () => {
    this.setState({
      count: this.state.count + 1,
    });
  };

  render() {
    const { userState: userInfo, logout, gotoProfile } = this.props;
    const userState = userInfo || {};

    const menu = (
      <Menu selectable={false}>
        <Menu.Item>
          <div className="rfq__widget__system-status__item">
            Xin chào,<strong> {userState.name}</strong>
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <div className="rfq__widget__system-status__item">
            <strong>Vai trò:</strong>{' '}
            {Array.isArray(userState.role)
              ? userState.role
                  .map((role) => role.replace('_R', ' (Chỉ xem)'))
                  .join(', ')
              : ''}
            <br />
            <strong>Phone:</strong> {userState.phone}
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a href="javascript: void(0);" onClick={gotoProfile(userState)}>
            <i className="topbar__dropdownMenuIcon icmn-user" />
            Thông tin cá nhân
          </a>
        </Menu.Item>

        <Menu.Divider />
        <Menu.Item>
          <a href="javascript: void(0);" onClick={logout}>
            <i className="topbar__dropdownMenuIcon icmn-exit" /> Đăng xuất
          </a>
        </Menu.Item>
      </Menu>
    );
    return (
      <div className="topbar__dropdown d-flex">
        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
          <a className="ant-dropdown-link" href="/">
            <Avatar className="topbar__avatar" userInfo={userState} />
          </a>
        </Dropdown>
      </div>
    );
  }
}

export default ProfileMenu;
