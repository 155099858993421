import React from "react";
import { connect } from "react-redux";
import { Avatar as AntdAvatar } from "antd";
import { getAvatarURL } from "utils";
import StarFilled from "@ant-design/icons/StarFilled";

type AvatarProps = {
  userInfo: any,
  avatarVersion: Number,
};

function Avatar({ userInfo, avatarVersion, ...rest }: AvatarProps) {
  const userName = userInfo && userInfo.name ? userInfo.name : "";
  const stars = (userInfo && userInfo.star_count) || 0;
  const splitName = (userName || "").trim().split(" ");
  const name = splitName[splitName.length - 1];
  const firstChar = name.charAt(0).toUpperCase();

  const radius = 20; // Bán kính của hình tròn
  const centerX = 20;
  const centerY = 20;
  const totalPositions = stars < 10 ? 10 : stars; // số vị trí
  const renderStars = Array.from({ length: totalPositions }, (_, i) => {
    const isOdd = i % 2 !== 0;
    const position = Math.floor((i + 1) / 2);
    const angle = (position / totalPositions) * Math.PI * 2 + Math.PI / 2; // Góc phân bố đều quanh hình tròn

    const x = centerX + (isOdd ? 1 : -1) * radius * Math.cos(angle);
    const y = centerY + radius * Math.sin(angle);

    return i < stars ? (
      <StarFilled
        key={i}
        style={{
          color: "#ffda0a",
          position: "absolute",
          fontSize: (13 / (totalPositions / 10)) * (i === 0 ? 1.2 : 1),
          left: `${x}px`,
          top: `${y}px`,
          transform: "translate(-50%, -50%)",
        }}
      />
    ) : null;
  });

  return (
    <div
      style={{ position: "relative", width: "fit-content", display: "flex" }}
    >
      <AntdAvatar
        size="large"
        src={getAvatarURL(userInfo, avatarVersion)}
        {...rest}
      >
        {firstChar}
      </AntdAvatar>
      {renderStars}
    </div>
  );
}
export default connect(({ app: { avatarVersion } }) => ({
  avatarVersion,
}))(Avatar);
